import * as React from 'react';
import { useEffect, useState } from 'react';
import ConnectionSection from '../components/landing/ConnectSection';
import HeaderSection from '../components/landing/HeaderSection';
import CreateSurvSect from '../components/landing/CreateSurvSect';
import Footer from '../components/footer';
import z from 'zod';
import { Options } from '../components/inputs/RadioGroup';
import SurveyModal from './SurveyModal';
import FirestoreService, { Emails } from '../services/FirestoreService';
import { Timestamp } from 'firebase/firestore';
const Landing = () => {
  const [email, setEmail] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [often, setOften] = useState<Options[]>([
    { value: 'Daily', label: 'Daily', isSelected: false },
    { value: 'Weekly', label: 'Weekly', isSelected: false },
    { value: 'Monthly', label: 'Monthly', isSelected: false },
    { value: 'Quarterly', label: 'Quarterly', isSelected: false }
  ]);
  const [thujaUse, setThujaUse] = useState<Options[]>([
    { value: 'Work', label: 'For work', isSelected: false },
    { value: 'Business', label: 'For my business', isSelected: false },
    { value: 'Research', label: 'For research', isSelected: false },
    { value: 'Personal', label: 'For personal use', isSelected: false }
  ]);
  const [selectedThuja, setSelectedThuja] = useState<string>('');
  const [selectedOften, setSelectedOften] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [isHovered, setIsHovered] = useState(false);
  const newUserSchema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
    emailVerified: z.boolean().refine(value => !value, { message: 'Already Subscribed' })
  });
  type Email = z.infer<typeof newUserSchema>;

  const handleEmailClick = async (e: any) => {
    e.preventDefault();
    const emailVer: Email = { email, emailVerified: false };
    const emailCheck = await FirestoreService.checkSurveyEmail(email);
    emailVer.emailVerified = !emailCheck;

    const validationResult = newUserSchema.safeParse(emailVer);

    if (validationResult.success) {
      setValidationErrors({});
      setIsModalOpen(prev => !prev);
    } else {
      const errors: Record<string, string> = {};
      let paths;
      validationResult.error.errors.forEach(error => {
        const path = error.path.join('.');
        paths = path;
        const message = error.message ?? '';
        errors[path] = message;
      });
      setValidationErrors(errors);
      setTimeout(() => {
        setValidationErrors({});
      }, 4000);
    }
  };
  const handleCloseInParent = () => {
    console.log('fire');
    console.log(isModalOpen);
    if (isModalOpen) {
      console.log('here');
      setIsModalOpen(false);
    }
  };

  const handleOftenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedOften(value);
  };

  const handleThujaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedThuja(value);
  };

  useEffect(() => {
    const updatedOptions = thujaUse.map(option => ({
      ...option,
      isSelected: option.value === selectedThuja
    }));
    setThujaUse(updatedOptions);
  }, [selectedThuja]);

  useEffect(() => {
    const updatedOptions = often.map(option => ({
      ...option,
      isSelected: option.value === selectedOften
    }));
    setOften(updatedOptions);
  }, [selectedOften]);

  const textToCopy = 'https://jointhuja.com';

  const handleCopyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setIsCopied(prev => !prev);
          console.log('Text copied to clipboard');
        })
        .then(() => {
          setTimeout(() => {
            setIsCopied(prev => !prev);
          }, 1200);
        })
        .catch(error => {
          console.error('Failed to copy text:', error);
        });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      setIsCopied(prev => !prev);
      console.log('Text copied to clipboard (fallback)');
    }
  };

  const handleZoom = () => {
    setIsHovered(prev => !prev);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      id="landing-page"
      className={` w-full min-h-screen mx-auto relative font-custom ${isModalOpen ? 'bg-bgblue' : 'bg-white'} `}
    >
      <HeaderSection
        isHovered={isHovered}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        handleZoom={handleZoom}
        validationErrors={validationErrors}
        handleEmailChange={(e: any) => setEmail(e.currentTarget.value)}
        value={email}
        handleEmailClick={handleEmailClick}
      />
      <ConnectionSection />
      <CreateSurvSect
        validationErrors={validationErrors}
        handleEmailChange={(e: any) => setEmail(e.currentTarget.value)}
        value={email}
        handleEmailClick={handleEmailClick}
      />
      <SurveyModal
        handleCloseInParent={handleCloseInParent}
        email={email}
        isCopied={isCopied}
        handleCopyToClipboard={handleCopyToClipboard}
        thujaUse={thujaUse}
        handleThujaChange={handleThujaChange}
        often={often}
        handleOftenChange={handleOftenChange}
        isModalOpen={isModalOpen}
        selectedThuja={selectedThuja}
        selectedOften={selectedOften}
      />
      <Footer />
    </div>
  );
};

export default Landing;
