import * as React from 'react';

import feedback from '../../../assets/Feedback.png';
import { useState } from 'react';

const FeedbackCard = () => (
  <div
    className={`grid place-content-center bg-grey md:p-10 rounded-[8px] h-60 sm:h-[17rem] md:h-[19rem] border-[1px] border-opacity-50 border-purple card-hover`}
  >
    <img src={feedback} alt="feedback" className={`w-10 h-10 sm:w-14 sm:h-14 mx-auto mb-3`} />
    <h3 className="text-2xl font-bold text-center leading-6 mb-3">
      Feedback on <br />
      autopilot
    </h3>
    <span className={`whitespace-nowrap text-center  text-sm md:text-base text-hover`}>
      Record your questions and create <br /> your survey logic once to capture <br /> deeper feedback.
    </span>
  </div>
);

export default FeedbackCard;
