import * as React from 'react';
import { Options } from '../components/inputs/RadioGroup';
import HowOftenModal from '../components/modals/HowOftenModal';

import UseThujaModal from '../components/modals/UseThujaModal';
import SurveyValueModal from '../components/modals/SurveyValueModal';
import WaitlistModal from '../components/modals/WaitlistModal';
import { useEffect, useState } from 'react';
import FirestoreService, { Waitlist } from '../services/FirestoreService';

type Props = {
  isModalOpen: boolean;
  often: Options[];
  handleOftenChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  thujaUse: Options[];
  handleThujaChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCopyToClipboard: () => void;
  isCopied: boolean;

  email: string;
  selectedThuja: string;
  selectedOften: string;
  handleCloseInParent: () => void;
};

const SurveyModal = ({
  isModalOpen,
  often,
  handleOftenChange,
  handleCopyToClipboard,
  handleThujaChange,
  thujaUse,
  isCopied,
  email,
  selectedThuja,
  selectedOften,
  handleCloseInParent
}: Props) => {
  const [isModalOption, setIsModalOption] = useState<string>('Survey');
  const handleOptionChange = (e: React.MouseEvent<HTMLButtonElement>, name: string) => {
    e.preventDefault();
    if (name === 'Skip' || name === 'Waitlist') {
      try {
        setIsModalOption(name);
        const data: Waitlist = { email: email, useFor: selectedThuja, howOften: selectedOften };
        FirestoreService.addToWaitlist(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsModalOption(name);
    }
  };
  function shareOnFacebook() {
    const url = encodeURIComponent('https://jointhuja.com/');
    const text = 'Come join me and complete the Thuja survey!';
    const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${url}&text=${text}`;

    window.open(facebookShareUrl, '_blank', 'width=550,height=450');
  }

  function shareOnLinkedIn() {
    const url = encodeURIComponent('https://jointhuja.com/'); // URL to be shared
    const share = 'Come join me and complete the Thuja survey!';
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${encodeURIComponent(
      share
    )}`;

    window.open(linkedInShareUrl, '_blank', 'width=550,height=450');
  }

  function shareOnTwitter() {
    const tweetContent = encodeURIComponent('Come join me and complete the Thuja survey!'); // Content to be shared
    const url = encodeURIComponent('https://jointhuja.com/'); // URL to be shared
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetContent}&url=${url}`;

    window.open(twitterShareUrl, '_blank', 'width=550,height=450');
  }

  useEffect(() => {
    if (!isModalOpen) {
      setIsModalOption('Survey');
    }
  }, [isModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const modalContainer = document.querySelector('.modal-container');
      if (modalContainer && !modalContainer.contains(target)) {
        handleCloseInParent();
        setIsModalOption('Survey');
      }
    };

    if (isModalOption === 'Skip' || isModalOption === 'Waitlist') {
      setTimeout(() => {
        document.addEventListener('click', handleClickOutside);
      }, 1000);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isModalOpen, isModalOption]);

  return (
    isModalOpen && (
      <div className="modal-container z-50">
        {<SurveyValueModal handleOptionChange={handleOptionChange} isModalOption={isModalOption} />}
        {
          <HowOftenModal
            isModalOption={isModalOption}
            handleOptionChange={handleOptionChange}
            often={often}
            handleOftenChange={handleOftenChange}
          />
        }
        {
          <UseThujaModal
            isModalOption={isModalOption}
            handleOptionChange={handleOptionChange}
            thujaUse={thujaUse}
            handleThujaChange={handleThujaChange}
          />
        }
        {
          <WaitlistModal
            shareOnFacebook={shareOnFacebook}
            shareOnLinkedIn={shareOnLinkedIn}
            shareOnTwitter={shareOnTwitter}
            isModalOpen={isModalOpen}
            isModalOption={isModalOption}
            handleCopyToClipboard={handleCopyToClipboard}
            isCopied={isCopied}
          />
        }
      </div>
    )
  );
};

export default SurveyModal;
