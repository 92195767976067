import * as React from 'react';
import { ModalName } from '../../types/types';

type Props = {
  handleOptionChange: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
  isModalOption: string;
};

const SurveyValueModal = ({ handleOptionChange, isModalOption }: Props) => {
  return (
    isModalOption === 'Survey' && (
      <div className="fixed rounded-[18px] sm:rounded-[32px] mx-auto left-[50%] -translate-x-[50%] w-[90%] sm:w-[32rem] top-[20%] bg-white z-50">
        <div className="grid h-full w-full place-content-center mt-6 sm:mt-12 mb-4">
          <h1 className="text-lg xs:text-xl font-bold text-center mb-4">We value your feedback</h1>
          <div className="text-[10px] xs:text-xs sm:text-sm text-greyish px-12 text-center whitespace-nowrap mb-3 sm:mb-0">
            Thank you for signing up for thuja! As a valued <br className="sm:hidden" /> member of our{' '}
            <br className="hidden sm:grid" /> community, we would love to <br className="sm:hidden" /> know how you’ll
            be using the <br className="hidden sm:grid" /> platform. Please <br className="sm:hidden" /> take a moment
            to fill out our post-signup <br className="hidden sm:grid" /> survey. <br className="sm:hidden" /> Your
            feedback will directly impact the future of <br className="sm:hidden" /> thuja{' '}
            <br className="hidden sm:grid" /> and ensure we meet your needs.
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 xs:px-8 sm:px-0 gap-y-2 sm:gap-y-0  sm:gap-x-4 h-9 mt-6 sm:mb-7 mb-20">
            <button
              onClick={e => handleOptionChange(e, 'Skip')}
              className="bg-inherit border-[1px] border-purple text-purple text-base sm:text-lg xs:text-xl h-full w-full sm:w-32 place-self-end rounded-[8px] py-2 sm:py-0"
            >
              Skip Survey
            </button>
            <button
              onClick={e => handleOptionChange(e, 'Often')}
              className="bg-purple h-full w-full sm:w-32 place-self-start text-white rounded-[8px] text-base sm:text-lg xs:text-xl py-2 sm:py-0"
            >
              Take Survey
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SurveyValueModal;
