import * as React from 'react';
import PrimaryInput from '../inputs/PrimaryInput';
import PrimaryButton from '../buttons/PrimaryButton';

type Props = {
  value: string;
  handleEmailChange: (e: any) => void;
  handleEmailClick: (e: any) => void;
  validationErrors: Record<string, string>;
};

const EmailFormBttm = ({ handleEmailChange, value, handleEmailClick, validationErrors }: Props) => (
  <form>
    <div className="mt-3 relative h-12 sm:h-8 xxl:h-10 items-center">
      <PrimaryInput
        type="text"
        placeHolder="Your Email Address"
        className="border-neutral placeholder:text-neutral pl-2 placeholder:text-sm sm:placeholder:text-xs text-purple text-sm sm:text-xs xxl:text-sm placeholder:text-center text-center sm:text-start sm:placeholder:text-start w-full sm:w-52 xl:w-60 xxl:w-72 h-full rounded-[4px] border-[1px] mr-1 mb-4 sm:mb-0 focus:outline-purple/75"
        value={value}
        name="email"
        handleChange={handleEmailChange}
      />
      <PrimaryButton
        className="bg-purple w-full sm:w-28 h-full rounded-[6px] text-white border-[1px] text-sm border-purple"
        name="Subscribe Here"
        onClick={handleEmailClick}
      />
      {validationErrors['email'] ? (
        <div className="mt-2 sm:mt-0 sm:mr-32 text-xs text-red-500 ">{validationErrors['email']}</div>
      ) : (
        <div className="mt-2 sm:mt-0 sm:mr-32 text-xs text-red-500 ">{validationErrors['emailVerified']}</div>
      )}
    </div>
  </form>
);

export default EmailFormBttm;
