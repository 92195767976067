import * as React from 'react';
import copypin from '../../assets/copypin.svg';
import twitter from '../../assets/twitter.svg';
import facebook from '../../assets/facebook.svg';
import linkedin from '../../assets/linkedin.svg';
import './style.css';
import CopyComment from '../landing/cards/CopyComment';
type Props = {
  handleCopyToClipboard: () => void;
  isCopied: boolean;
  isModalOption: string;
  isModalOpen: boolean;
  shareOnLinkedIn: () => void;
  shareOnFacebook: () => void;
  shareOnTwitter: () => void;
};

const WaitlistModal = ({
  isCopied,
  isModalOption,
  isModalOpen,
  handleCopyToClipboard,
  shareOnFacebook,
  shareOnLinkedIn,
  shareOnTwitter
}: Props) => {
  const isModalOptionValid = isModalOption === 'Skip' || isModalOption === 'Waitlist';
  return (
    isModalOptionValid &&
    isModalOpen && (
      <div
        className="fixed w-[90%] rounded-[18px] sm:rounded-[32px] mx-auto left-[50%] -translate-x-[50%]  sm:w-[32rem] top-[20%] bg-white z-50"
        id="Share-Waitlist"
      >
        <div className="grid h-full w-full place-content-center mt-12 mb-4">
          <h1 className="text-xl font-bold text-center mb-4">You're Subscribed!</h1>

          {isModalOption !== 'Skip' && (
            <div className="text-[11px] xs:text-sm text-greyish text-center mb-3">
              Thanks for completing the survey.
            </div>
          )}
          <div className="text-[11px] xs:text-sm text-greyish px-12 text-center whitespace-nowrap">
            We're thrilled to have you onboard and can't wait <br className="sm:hidden" /> to{' '}
            <br className="hidden sm:grid" /> show you how thuja makes capturing insights <br className="sm:hidden" />{' '}
            more <br className="hidden sm:grid" /> engaging and effective than traditional <br className="sm:hidden" />{' '}
            surveys.
          </div>
          <div className="text-[11px] xs:text-sm text-greyish text-center sm:my-3 mb-8 mt-2">
            Refer your friends to join thuja.
          </div>
          <div className="grid grid-cols-3 h-12 sm:h-8 mx-16 xs:mx-24 gap-x-4 sm:mx-4">
            <a
              onClick={shareOnTwitter}
              className="grid sm:grid-cols-2 place-content-center hover:cursor-pointer bg-[#03A9F4] rounded-[6px]"
            >
              <img
                className="grid sm:-mr-1 justify-self-center pb-2 sm:pb-0 sm:w-6  h-full"
                src={twitter}
                alt="twitter"
              />
              <span className="hidden sm:grid h-full text-white sm:-ml-1.5 text-[10px] items-center">Tweet</span>
            </a>
            <a
              onClick={shareOnFacebook}
              className="grid sm:grid-cols-2 place-content-center bg-[#1877F2] rounded-[6px] hover:cursor-pointer"
            >
              <img
                className="grid sm:-mr-1 sm:mb-1 justify-self-center h-10 sm:h-7 pb-2 pt-1 sm:pb-1"
                src={facebook}
                alt="facebook"
              />
              <span className="hidden sm:grid h-full text-white sm:-ml-1.5 text-[10px] items-center">Share</span>
            </a>
            <a
              onClick={shareOnLinkedIn}
              className="grid sm:grid-cols-2 place-content-center bg-[#0077B5] rounded-[6px] hover:cursor-pointer"
            >
              <img
                className="grid sm:-mr-2 sm:pb-1.5 justify-self-center pb-2 sm:w-6 h-full"
                src={linkedin}
                alt="linkedin"
              />
              <span className="hidden sm:grid h-full text-white sm:-ml-1.5 text-[10px] items-center">Share</span>
            </a>
          </div>
          <div className="flex relative w-full gap-x-4 h-12 sm:h-9 mt-6 mb-6">
            {isCopied && <CopyComment />}
            <div className="flex flex-row h-full w-[65%] xs:w-3/4 sm:w-1/2 mx-auto text-purple justify-center bg-grey rounded-[8px] sm:rounded-[4px] text-xl hover:cursor-pointer py-5 sm:py-0">
              <button onClick={handleCopyToClipboard} className="flex mr-3 text-sm self-center">
                https//jointhuja.com{' '}
              </button>
              <img src={copypin} alt="copypin" className="flex self-center w-4 h-4" />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default WaitlistModal;
