import * as React from 'react';
import launch from '../../../assets/launch.png';

const CreateThreeCard = () => (
  <div className="grid row-span-1 sm:grid-cols-2 mt-16">
    <div className="grid col-span-1 relative">
      <div className="absolute left-1/2 -translate-x-1/2 sm:translate-x-0 sm:left-0 -top-4 w-9 h-9 rounded-full text-white bg-purple place-content-center grid text-xl font-bold">
        <span>3</span>
      </div>
      <img className="w-full h-full object-cover" src={launch} />
    </div>
    <div className="sm:pl-5 sm:pt-5 text-center sm:text-start">
      <h3 className="text-xl xs:text-2xl font-bold whitespace-nowrap md:text-2xl lg:text-3xl xl:text-4xl my-5 sm:my-0">
        Launch your survey
      </h3>
      <div className="text-greyish leading-5 whitespace-nowrap text-[10px] xs:text-sm md:text-base lg:text-lg xl:text-xl">
        With your questions in place, it's time to <br className="hidden sm:grid" /> launch your{' '}
        <br className="sm:hidden" />
        survey and start receiving <br className="hidden sm:grid" /> responses in your <br className="sm:hidden" />{' '}
        preferred format. Thuja <br className="hidden sm:grid" /> ensures data security and <br className="sm:hidden" />
        privacy, and <br className="hidden sm:grid" /> you'll have access to powerful analytics <br /> tools to gain
        meaningful insights from the <br className="hidden sm:grid" />
        collected <br className="sm:hidden" /> responses.
      </div>
    </div>
  </div>
);

export default CreateThreeCard;
