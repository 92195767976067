import * as React from 'react';
import RadioGroup, { Options } from '../inputs/RadioGroup';
import { ModalName } from '../../types/types';

type Props = {
  handleOftenChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  often: Options[];
  handleOptionChange: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
  isModalOption: string;
};

const HowOftenModal = ({ often, handleOftenChange, handleOptionChange, isModalOption }: Props) => {
  return (
    isModalOption === 'Often' && (
      <div className="fixed rounded-[18px] sm:rounded-[32px] mx-auto left-[50%] -translate-x-[50%] w-[90%] sm:w-[32rem] top-[20%] bg-white z-50">
        <div className="grid h-full w-full mx-auto place-content-center mt-8 sm:mt-12 mb-4">
          <div
            className="grid grid-cols-2 h-[5px] w-full 
        sm:w-[320px] xs:gap-4 mb-4"
          >
            <div className=" bg-purple w-[6.25rem] xs:w-32 sm:w-36 mx-auto sm:mx-0"></div>
            <div className=" bg-grey w-[6.25rem] xs:w-32 sm:w-36 mx-auto sm:mx-0"></div>
          </div>
          <h1 className="text-base xs:text-xl sm:text-lg font-bold sm:text-center mb-4 text-start whitespace-nowrap">
            How often do you plan to use <br className="sm:hidden" /> thuja?
          </h1>
          <div className="grid text-base text-center place-content-start whitespace-nowrap">
            <form>
              <RadioGroup name={'often'} options={often} handleChange={handleOftenChange} />
            </form>
          </div>
          <div className="grid w-full gap-x-4 h-10 mt-6 mb-6">
            <button
              onClick={e => handleOptionChange(e, 'Thuja')}
              className="bg-purple h-full w-full place-self-start text-white rounded-[8px] text-base py-3 sm:py-0"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default HowOftenModal;
