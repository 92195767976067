import * as React from 'react';

type Props = {
  value: string;
  placeHolder: string;
  className: string;
  name: string;
  type: string;
  handleChange: (e: any) => void;
};

const PrimaryInput = ({ value, placeHolder, className, name, type, handleChange }: Props) => (
  <input
    type={type}
    placeholder={placeHolder}
    className={className}
    value={value}
    name={name}
    onChange={handleChange}
  />
);

export default PrimaryInput;
