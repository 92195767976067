import React from 'react'
import heroTop from '../hero-top.png'
import herobr from '../hero-br.png';
import herobl from '../hero-bl.png'
import BackgroundHero from '../../components/landing/cards/BackgroundHero';
type Props = {
    className: string;
}


const PeopleHero = ({className}: Props) => (
    <div className='hero-container relative grid grid-cols-2  bg-white  xs:-ml-3 w-[90%] xs:w-full lg:h-[90%]'>
    <div className={`col-span-2 relative w-full h-full zoom-container z-20`}>
    <img src={heroTop} className={'w-full h-full object-cover'} />
    <button className='absolute top-4 right-0 h-[18%] w-[24%] xs:h-[12%] xs:w-[18%] bg-grey text-purple rounded-[5px] text-[8px] xs:text-[10px] sm:text-[8px] lg:text-[10px] p-1'>Video</button>
    </div>
    <div className={`relative w-full h-full zoom-container-br z-20`}>
    <img src={herobl} className={'w-full h-full object-cover'} />
    <button className='absolute bottom-8 left-0 h-[18%] w-[48%] xs:h-[12%] xs:w-[36%] bg-grey text-purple rounded-[5px] text-[8px] xs:text-[10px] sm:text-[8px] lg:text-[10px] p-1'>Text</button>
    </div>
    <div className={`relative w-full h-full zoom-container-bl z-20`}>
    <img src={herobr} className={'w-full h-full object-cover'} />
    <button className='absolute top-2 xs:top-8 right-0 h-[18%] w-[48%] xs:h-[12%] xs:w-[36%] bg-grey text-purple rounded-[5px] text-[8px] xs:text-[10px] sm:text-[8px] lg:text-[10px] p-1'>Audio</button>
    </div>
    <BackgroundHero />
    </div>

)
export default PeopleHero;