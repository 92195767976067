import * as React from 'react';
import FeedbackCard from './cards/FeedbackCard';
import PrivacyCard from './cards/PrivacyCard';
import AnalyticsCard from './cards/AnalyticsCard';
import BlockchainCard from './cards/BlockchainCard';

const ConnectionSection = () => {
  return (
    <section>
      <div className="sm:grid sm:grid-cols-2 sm:mt-6 md:mt-12 w-11/12 sm:w-10/12 max-w-[90rem] mx-auto gap-6">
        <div className="sm:grid sm:col-span-2 text-center">
          <h1 className=" text-title-2  mt-14 mb-10 text-black whitespace-nowrap font-bold">
            Connecting businesses <br className="sm:hidden" /> to their
            <br className="hidden sm:grid" /> audience, like <br className="sm:hidden" />
            <span className="text-purple whitespace-nowrap"> never before</span>
          </h1>
        </div>
        <div className="hidden sm:grid sm:col-span-1 w-full gap-y-6 sm:gap-y-6 mb-6 sm:mb-0">
          <FeedbackCard />
          <PrivacyCard />
        </div>
        <div className="hidden sm:grid sm:col-span-1 w-full gap-y-6 sm:gap-y-6">
          <AnalyticsCard />
          <BlockchainCard />
        </div>
        <div className="grid col-span-1 sm:hidden w-full gap-y-6 sm:gap-y-4 mb-6 sm:mb-0">
          <FeedbackCard />
          <AnalyticsCard />
          <PrivacyCard />
          <BlockchainCard />
        </div>
      </div>
    </section>
  );
};

export default ConnectionSection;
