import * as React from 'react';
import '../style.css';
import PeopleHero from '../../../assets/images/PeopleHero';
type Props = {
  isHovered: boolean;
  handleZoom: () => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const HeaderImageCard = ({ handleMouseEnter, handleMouseLeave, handleZoom, isHovered }: Props) => {
  return (
    <>
      <div
        className="grid relative col-span-1 mt-28 sm:mt-0 w-[90%] mx-auto"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="relative w-full h-full">
          <PeopleHero className="" />
        </div>
      </div>
    </>
  );
};

export default React.memo(HeaderImageCard);
