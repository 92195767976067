import * as React from 'react';

type Props = {
  className: string;
  name: string;
  onClick: (e: any) => void;
};

const PrimaryButton = ({ className, name, onClick }: Props) => (
  <button className={className} onClick={onClick}>
    {name}
  </button>
);

export default PrimaryButton;
