import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import CustomRouter from './routes';
// import Landing from './views/Landing';

const Source = () => {
  return (
    <BrowserRouter>
      <CustomRouter />
    </BrowserRouter>
  );
};

const container = document.getElementById('app');

if (!container) throw new Error('Cannot find element');

const root = createRoot(container);
root.render(<Source />);
