import * as React from 'react';
import PrimaryInput from '../inputs/PrimaryInput';
import PrimaryButton from '../buttons/PrimaryButton';

type Props = {
  handleChangeEmail: (e: any) => void;
  handleChangeMessage: (e: any) => void;
  email: string;
  message: string;
  validationErrors: Record<string, string>;
  handleEmailClick: (e: any) => void;
};

const ContactForm = ({
  handleChangeEmail,
  handleEmailClick,
  handleChangeMessage,
  email,
  message,
  validationErrors
}: Props) => {
  return (
    <form>
      <div className="flex flex-col relative mt-3 items-center z-50 ">
        <PrimaryInput
          type="text"
          placeHolder="Your Email Address"
          className="border-neutral placeholder:text-neutral text-purple placeholder:text-start pl-2  placeholder:text-xs text-xs text-start  w-52 xl:w-60 xxl:w-72 h-8  rounded-[4px] border-[1px] mr-1 xl:mr-1.5 mb-1 focus:outline-purple/75"
          value={email}
          name="email"
          handleChange={handleChangeEmail}
        />
        <textarea
          placeholder="Your Message"
          className="border-neutral resize-none placeholder:text-neutral text-purple pl-2 place-holder:text-start placeholder:text-xs text-xs w-52 xl:w-60 xxl:w-72 h-14 rounded-[4px] border-[1px] mr-1 xl:mr-1.5 mb-5 z-0  focus:outline-purple/75 "
          value={message}
          name="email"
          onChange={handleChangeMessage}
        />

        <PrimaryButton
          className="bg-purple h-8 w-28 lg:w-32 rounded-[8px] text-white text-sm sm:text-sm mb-12"
          name="Send"
          onClick={handleEmailClick}
        />

        {validationErrors['email'] ? (
          <div className="absolute bottom-20 place-content-center text-[10px] sm:text-xs text-center sm:text-left text-red-500 ">
            {validationErrors['email']}
          </div>
        ) : (
          <div className="absolute bottom-20 place-content-center text-[10px] sm:text-xs text-center sm:text-left text-red-500 ">
            {validationErrors['message']}
          </div>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
