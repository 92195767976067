import * as React from 'react';
import choose from '../../assets/Choose.svg';
import EmailFormBttm from './EmailFormBttm';
import CreateCardOne from './cards/CreateOneCard';
import CreateTwoCard from './cards/CreateTwoCard';
import CreateThreeCard from './cards/CreateThreeCard';

type Props = {
  value: string;
  handleEmailChange: (e: any) => void;
  handleEmailClick: (e: any) => void;
  validationErrors: Record<string, string>;
};

const CreateSurvSect = ({ value, handleEmailChange, handleEmailClick, validationErrors }: Props) => (
  <section>
    <div className="grid grid-rows-1 mt-20 w-11/12 sm:w-10/12 md:w-8/12 max-w-7xl mx-auto mb-28">
      <div className="grid row-span-1 mb-5 sm:mb-0">
        <h1 className="text-title-2 -mt-5 sm:mt-0 mb-6 text-black font-bold leading-8 md:leading-[3rem] lg:leading-[3.5rem] xl:leading-[4rem] xxl:leading-[5rem] text-center whitespace-nowrap">
          Create your survey in
          <br />
          <span className="text-purple whitespace-nowrap">3 easy steps</span>
        </h1>
        <span className="whitespace-nowrap text-[10px] xs:text-sm sm:text-lg lg:text-lg text-center text-greyish">
          Capture feedback and insights in a more engaging <br className="hidden sm:grid" /> and{' '}
          <br className="sm:hidden" /> effective way than traditional surveys.
        </span>
      </div>
      <CreateCardOne />
      <CreateTwoCard />
      <CreateThreeCard />
      <div className="grid row-span-1 my-20 text-center">
        <h1 className="text-3xl xs:text-4xl xl:text-5xl xxl:text-6xl font-bold">Sign up now!</h1>
        <span className="mt-3 mb-2 xl:mt-5 xl:mb-4 text-sm xl:text-md text-greyish">
          Join now to gain access to our services.
        </span>
        <EmailFormBttm
          validationErrors={validationErrors}
          value={value}
          handleEmailChange={handleEmailChange}
          handleEmailClick={handleEmailClick}
        />
      </div>
    </div>
  </section>
);

export default CreateSurvSect;
