import * as React from 'react';
import { useContext } from 'react';

// import { AuthContext } from '../contexts/auth';
// import AuthLayout from '../layouts/Auth';

// Nonauth Views
// Authenticated Views;
import Landing from '../views/Landing';

interface RoutesObj {
  path: string;
  element: React.ReactNode;
  key: string;
}

const useRoutes = (): RoutesObj[] => {
  // const user = useContext(AuthContext);

  // if (user)
  //   return [
  //     {
  //       path: '*',
  //       element: <Movies />,
  //       key: '*'
  //     }
  //   ];

  return [
    {
      path: '*',
      element: <Landing />,
      key: '*'
    },
    {
      path: '/',
      element: <Landing />,
      key: '/'
    }
  ];
};

export default useRoutes;
