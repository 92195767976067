import * as React from 'react';
import privacy from '../../../assets/Privacy.png';
import { useState } from 'react';

const PrivacyCard = () => (
  <div
    className={`grid place-content-center bg-grey md:p-10 rounded-[8px] h-60 sm:h-[17rem] md:h-[19rem] border-[1px] border-opacity-50 border-purple card-hover`}
  >
    <img src={privacy} alt="privacy" className={`w-10 h-10 sm:w-14 sm:h-14 mx-auto mb-3`} />
    <h3 className="text-2xl font-bold text-center leading-6 mb-3">
      Privacy-focused <br />
      approach
    </h3>
    <span className={`whitespace-nowrap text-center  text-sm  md:text-base text-hover`}>
      You decide how your data is used.
      <br /> We keep everything else safe.
    </span>
  </div>
);

export default PrivacyCard;
