import React from 'react';

const BackgroundHero = () => {
  return (
    <div className="grid absolute z-0 -bottom-6 -right-6 w-full h-full">
      <div className="border-[1px] border-purple bg-grey rounded-bl-[20px] rounded-br-[20px] rounded-tl-[20px]">
        <div className="w-full h-full grid my-auto p-[15%]">
          <div className=" border-[1px] border-purple bg-grey rounded-[20px] object-cover">
            <div className="w-full h-full grid my-auto p-[20%]">
              <div className=" border-[1px] border-purple bg-grey rounded-[20px] object-cover"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BackgroundHero;
