import * as React from 'react';
import analytic from '../../../assets/Analytics.png';

const AnalyticsCard = () => (
  <div
    className={`grid place-content-center bg-grey md:p-10 rounded-[8px] h-60 sm:h-[17rem] md:h-[19rem] border-[1px] border-purple border-opacity-50 card-hover`}
  >
    <img src={analytic} alt="analytic" className={`w-10 h-10 sm:w-14 sm:h-14 mx-auto mb-3 `} />
    <h3 className="text-2xl font-bold text-center leading-6 mb-3">
      Advanced analytics <br />& reporting
    </h3>
    <span className={`whitespace-nowrap text-center text-sm md:text-base text-hover`}>
      Harness the power of AI to analyze
      <br /> any kind of response. Save time on
      <br /> reporting.
    </span>
  </div>
);
export default AnalyticsCard;
