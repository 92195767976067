import * as React from 'react';
import blockchain from '../../../assets/Blockchain.png';
import { useState } from 'react';

const BlockchainCard = () => (
  <div
    className={`grid place-content-center bg-grey md:p-10 rounded-[8px] h-60 sm:h-[17rem] md:h-[19rem] border-[1px] border-purple border-opacity-50 card-hover`}
  >
    <img src={blockchain} alt="blockchain" className={`w-10 h-10 sm:w-14 sm:h-14 mx-auto mb-3`} />
    <h3 className="text-2xl font-bold text-center leading-6 mb-3">
      Web-based <br />
      verification
    </h3>
    <span className={`whitespace-nowrap text-center text-sm md:text-base text-hover`}>
      Verify users through privacy-
      <br />
      conscious methods. Ensure quality
      <br /> control across every survey.
    </span>
  </div>
);

export default BlockchainCard;
