
import { collection, getDocs, addDoc, serverTimestamp, query, where, Timestamp } from 'firebase/firestore'
import {firestore} from '../firebase.config'

export type Waitlist = {
  email: string;
  useFor: string;
  howOften: string;
}
export type Emails = {
  email: string;
  message: string;
}

export class FirestoreService {

      public static async checkSurveyEmail(email: string) {
        try{
          const surveysCollectionRef = collection(firestore, "surveys");
          const q = query(surveysCollectionRef, where('createdBy', '==', email));
          const querySnapshot = await getDocs(q);
          
          if (querySnapshot.empty) {
            return true
          } else {
            return false
          }

        } catch(error) {
          console.log(error);
          throw error;
        }
      }
 
      public static async addToWaitlist(waitlist: Waitlist) {
        try {
          const surveysCollectionRef = collection(firestore, "surveys");
          const q = query(surveysCollectionRef, where('createdBy', '==', waitlist.email));
          const querySnapshot = await getDocs(q);
          
          if (querySnapshot.empty) {
            const surveyData = {
              createdBy: waitlist.email,
              useFor: waitlist.useFor !== '' ? waitlist.useFor : 'N/A',
              howOften: waitlist.howOften !== '' ? waitlist.howOften : 'N/A',
              createdAt: serverTimestamp(),
            };
            await addDoc(surveysCollectionRef, surveyData);
            console.log('doc created');
          } else {
            console.log('email already in use');
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }

      public static async addToContactEmails(emails: Emails) {
        try {
          const surveysCollectionRef = collection(firestore, "emails");
 
            const surveyData = {
              createdBy: emails.email,
              message: emails.message,
              createdAt: serverTimestamp(),
            };
            await addDoc(surveysCollectionRef, surveyData);
            console.log('doc created');

        } catch (error) {
          console.error(error);
          throw error;
        }
      }

      public static async getAllEmailsForCurrentDay() {
        try {
          const surveysCollectionRef = collection(firestore, "emails");

          const currentDate = new Date();
          currentDate.setUTCHours(currentDate.getUTCHours() - 7);
    
          const startDateTime = new Date(currentDate);
          startDateTime.setDate(startDateTime.getDate() - 1); 
          startDateTime.setUTCHours(18, 0, 0, 0); 
          console.log('running')
          const endDateTime = new Date(currentDate);
          endDateTime.setUTCHours(28, 59, 59, 999); 

          const startTimestamp = Timestamp.fromDate(startDateTime);
          const endTimestamp = Timestamp.fromDate(endDateTime);
    
          const q = query(
            surveysCollectionRef,
            where("createdAt", ">=", startTimestamp),
            where("createdAt", "<=", endTimestamp)
          );
    
          const querySnapshot = await getDocs(q);
          console.log(querySnapshot)
          const waitlistEntries: any[] = [];
          querySnapshot.forEach((doc) => {
            waitlistEntries.push({ id: doc.id,  ...doc.data() });
          });
          console.log('data retrieved', waitlistEntries)
          
          return waitlistEntries;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }

        public static async getAllWaitlistForCurrentDay() {
            try {
              const surveysCollectionRef = collection(firestore, "surveys");
  
              const currentDate = new Date();
              currentDate.setUTCHours(currentDate.getUTCHours() - 7);
        
              const startDateTime = new Date(currentDate);
              startDateTime.setDate(startDateTime.getDate() - 1); 
              startDateTime.setUTCHours(18, 0, 0, 0); 
        
              const endDateTime = new Date(currentDate);
              endDateTime.setUTCHours(24, 59, 59, 999); 

              const startTimestamp = Timestamp.fromDate(startDateTime);
              const endTimestamp = Timestamp.fromDate(endDateTime);
        
              const q = query(
                surveysCollectionRef,
                where("createdAt", ">=", startTimestamp),
                where("createdAt", "<=", endTimestamp)
              );
        
              const querySnapshot = await getDocs(q);

              const waitlistEntries: any[] = [];
              querySnapshot.forEach((doc) => {
                waitlistEntries.push({ id: doc.id, ...doc.data() });
              });
              console.log('data retrieved', waitlistEntries)
              return waitlistEntries;
            } catch (error) {
              console.error(error);
              throw error;
            }
          }
}
export default FirestoreService
