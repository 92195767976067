import * as React from 'react';
import choose from '../../../assets/choose.png';

const CreateCardOne = () => (
  <div className="grid row-span-1 sm:grid-cols-2 mt-12">
    <div className="grid col-span-1 relative">
      <div className="absolute left-1/2 -translate-x-1/2 sm:translate-x-0 sm:left-0 -top-4 w-9 h-9 rounded-full text-white bg-purple place-content-center grid text-xl font-bold">
        <span>1</span>
      </div>
      <img className="w-full h-full object-cover" src={choose} />
    </div>
    <div className="sm:pl-5 sm:pt-5 text-center sm:text-start">
      <h3 className="text-xl xs:text-2xl font-bold whitespace-nowrap md:text-2xl lg:text-3xl xl:text-4xl mt-7 mb-3 sm:mt-0 sm:mb-0">
        Choose your survey
      </h3>
      <div className="text-greyish leading-5 whitespace-nowrap text-center  sm:text-start text-[10px] xs:text-sm md:text-base lg:text-lg xl:text-xl">
        Select the subject or theme for your survey. <br /> Whether it's collecting customer feedback, <br /> conducting
        market research, or getting <br className="hidden sm:grid" /> employee <br className="sm:hidden" /> insights,
        our platform provides <br className="hidden sm:grid" /> the flexibility to suit <br className="sm:hidden" />{' '}
        your needs. Define the <br className="hidden sm:grid" />
        purpose and goals of your <br className="sm:hidden" /> survey to guide <br className="hidden sm:grid" /> the
        questions you'll ask.
      </div>
    </div>
  </div>
);

export default CreateCardOne;
