import * as React from 'react';

type Props = {
  name: string;
  options: Options[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export type Options = {
  value: any;
  label: string;
  isSelected: boolean;
};

const RadioGroup = ({ name, options, handleChange }: Props) => {
  return options.map((option, i) => (
    <div className="flex place-content-center justify-start mb-4 sm:mb-1" key={option.value + i}>
      <input
        onChange={handleChange}
        value={option.value}
        type="radio"
        name={name}
        checked={option.isSelected}
        className="mr-3 sm:mr-2 mt-1 sm:mt-0 text-grayish"
      />
      <label>{option.label}</label>
    </div>
  ));
};

export default RadioGroup;
