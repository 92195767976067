import * as React from 'react';
import thuja from '../../assets/thuja-purple.svg';
import EmailFormTop from './EmailFormTop';
import './style.css';
import BackgroundHero from './cards/BackgroundHero';
import PeopleOneHero from '../../assets/images/PeopleOneHero';
import PeopleThreeHero from '../../assets/images/PeopleThreeHero';
import PeopleTwoHero from '../../assets/images/PeopleTwoHero';
import PeopleHero from '../../assets/images/PeopleHero';
import HeaderImageCard from './cards/HeaderImageCard';
type Props = {
  value: string;
  handleEmailChange: (e: any) => void;
  handleEmailClick: (e: any) => void;
  validationErrors: Record<string, string>;
  isHovered: boolean;
  handleZoom: () => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

const HeaderSection = ({
  value,
  handleEmailChange,
  handleEmailClick,
  validationErrors,
  handleMouseEnter,
  handleMouseLeave,
  handleZoom,
  isHovered
}: Props) => {
  return (
    <section>
      <div className="grid relative sm:grid-cols-2 pt-20 w-11/12 md:w-10/12 mx-auto max-w-[90rem]">
        <div className="grid place-content-center md:place-content-baseline">
          <img src={thuja} className="mx-auto sm:mx-0 w-28 sm:w-40 sm:-mt-6 md:mt-0 " />
          <h1 className="text-title text-center sm:text-start mt-6 mb-6 text-purple font-bold leading-[2.2rem] xs:leading-[3rem] sm:leading-[2.5rem] md:leading-[3rem] lg:leading-[3.5rem] xl:leading-[4rem] xxl:leading-[4.25rem] xxxl:leading-[5rem]">
            Revolutionize
            <br />
            <span className="text-black whitespace-nowrap">
              your survey <br />
              experience
            </span>
          </h1>
          <span className="whitespace-nowrap text-greyish text-[10px] xs:text-sm sm:text-xs md:text-sm lg:text-base xxl:text-xl text-center sm:text-start mt-2 mb-3 sm:mt-0 sm:mb-4 xxl:my-8">
            Capture feedback and insights in a more engaging <br /> and effective way than traditional surveys.
          </span>
          <EmailFormTop
            validationErrors={validationErrors}
            className="hidden sm:block"
            value={value}
            handleEmailChange={handleEmailChange}
            handleEmailClick={handleEmailClick}
          />
        </div>
        <EmailFormTop
          validationErrors={validationErrors}
          className="block sm:hidden"
          value={value}
          handleEmailChange={handleEmailChange}
          handleEmailClick={handleEmailClick}
        />
        <HeaderImageCard
          isHovered={isHovered}
          handleZoom={handleZoom}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      </div>
    </section>
  );
};

export default React.memo(HeaderSection);
