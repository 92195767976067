import * as React from 'react';
import thuja from '../../assets/thuja.svg';
import copy from '../../assets/copyright.svg';
import { useEffect, useRef, useState } from 'react';

import FirestoreService, { Emails } from '../../services/FirestoreService';
import ContactForm from './ContactForm';
import ContactModal from '../modals/ContactModal';
import z from 'zod/lib';

const Footer = () => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const newUserSchema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
    message: z
      .string()
      .min(2, { message: 'Message length of 2 char required' })
      .max(150, { message: 'Message length of 150 char exceeded' })
  });
  type Email = z.infer<typeof newUserSchema>;

  const handleEmailClick = async (e: any) => {
    e.preventDefault();
    const emailVer: Email = { email, message };

    const validationResult = newUserSchema.safeParse(emailVer);

    if (validationResult.success) {
      setValidationErrors({});
      const emails = { email, message };
      FirestoreService.addToContactEmails(emails).then(() => {
        setEmail('');
        setMessage('');
        setFeedbackSent(true);
      });
    } else {
      const errors: Record<string, string> = {};
      let paths;
      validationResult.error.errors.forEach(error => {
        const path = error.path.join('.');
        paths = path;
        const message = error.message ?? '';
        errors[path] = message;
      });
      setValidationErrors(errors);
      setTimeout(() => {
        setValidationErrors({});
      }, 4000);
    }
  };
  const handleClose = (e: React.MouseEvent) => {
    setIsFormOpen(false);
    setFeedbackSent(false);
  };
  return (
    <footer>
      <div className="grid grid-rows-1 bg-mainblue mt-5 sm:mb-0 w-full">
        <div className="flex flex-row sm:grid-cols-2 w-full pt-12 pb-6">
          <div className="grid mx-auto sm:mx-0 sm:col-span-1 place-content-center sm:place-content-start sm:ml-7 md:ml-12 lg:ml-20 xl:ml-28 xxl:ml-32">
            <img src={thuja} alt="thuja-logo" className="w-28 sm:w-40 grid col-span-1" />
          </div>
          <div className="hidden sm:grid w-full">
            {isFormOpen && (
              <ContactModal
                handleClose={handleClose}
                email={email}
                feedbackSent={feedbackSent}
                message={message}
                handleChangeEmail={(e: any) => setEmail(e.currentTarget.value)}
                handleChangeMessage={(e: any) => setMessage(e.currentTarget.value)}
                handleEmailClick={handleEmailClick}
                validationErrors={validationErrors}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col sm:grid sm:grid-cols-2 place-content-start w-full pb-32">
          <div className="grid sm:col-span-1 place-content-center sm:place-content-start  sm:ml-7 md:ml-12 lg:ml-20 xl:ml-32 xxl:ml-40 text-white">
            <span className="whitespace-nowrap text-[10px] xs:text-xs sm:text-[10px] md:text-xs lg:text-base text-center sm:text-start">
              Capture feedback and insights in a more engaging and <br /> effective way than traditional surveys.
            </span>
          </div>
          <div className="flex flex-col-reverse sm:grid sm:col-span-1 sm:grid-cols-2">
            <div className="sm:grid flex flex-row sm:grid-cols-10 relative w-full mx-auto sm:mx-0  sm:w-full text-white whitespace-nowrap text-xs md:text-sm lg:text-lg place-content-center">
              <div className="sm:grid h-full sm:col-span-1 place-content-center">
                <img className="h-5 w-5" src={copy} alt="copyright" />
              </div>
              <span className="ml-1 text-sm sm:text-[10px] md:text-sm lg:text-base">
                2023 Thuja all rights reserved
              </span>
            </div>
            <div className="grid place-self-center relative w-11/12 h-full">
              <div className="grid sm:hidden w-full h-full ">
                {isFormOpen && (
                  <ContactModal
                    handleClose={handleClose}
                    email={email}
                    message={message}
                    feedbackSent={feedbackSent}
                    handleChangeEmail={(e: any) => setEmail(e.currentTarget.value)}
                    handleChangeMessage={(e: any) => setMessage(e.currentTarget.value)}
                    handleEmailClick={handleEmailClick}
                    validationErrors={validationErrors}
                  />
                )}
              </div>
              <span
                onClick={e => setIsFormOpen(true)}
                className="grid place-self-center text-white text-sm sm:text-[10px] md:text-sm py-8 sm:py-0 lg:text-base xl:text-lg underline hover:cursor-pointer z-0"
              >
                Contact Us
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
