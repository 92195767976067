import * as React from 'react';

const CopyComment = () => {
  return (
    <div className="absolute -top-10 right-[50%] translate-x-[50%] ">
      <div className="legend">
        <span className="legend-text">Copied</span>
        <div className="comment-tail"></div>
      </div>
    </div>
  );
};

export default CopyComment;
