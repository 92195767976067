import * as React from 'react';
import uploads from '../../../assets/Uploads.png';

const CreateTwoCard = () => (
  <div className="grid row-span-1 sm:grid-cols-2 mt-16">
    <div className="grid col-span-1 relative">
      <div className="absolute left-1/2 -translate-x-1/2 sm:translate-x-0 sm:left-0 -top-4 w-9 h-9 rounded-full text-white bg-purple place-content-center grid text-xl font-bold">
        <span>2</span>
      </div>
      <img className="w-full h-full object-cover" src={uploads} />
    </div>
    <div className="sm:pl-5 sm:pt-5 text-center sm:text-start">
      <h3 className="text-xl xs:text-2xl font-bold whitespace-nowrap md:text-2xl lg:text-3xl xl:text-4xl my-5 sm:my-0">
        Upload your questions
      </h3>
      <div className="text-greyish leading-5 whitespace-nowrap text-[10px] xs:text-sm md:text-base lg:text-lg xl:text-xl">
        Craft a compelling set of questions that will <br className="hidden sm:grid" /> capture{' '}
        <br className="sm:hidden" /> the essence of your survey. Our <br className="hidden sm:grid" /> platform supports{' '}
        <br className="sm:hidden" /> various question formats, <br className="hidden sm:grid" /> including text, video
        and <br className="sm:hidden" /> audio, ensuring <br className="hidden sm:grid" /> you can gather the specific{' '}
        <br className="sm:hidden" /> information you <br className="hidden sm:grid" /> require. Make sure your questions{' '}
        <br className="sm:hidden" /> are clear <br className="hidden sm:grid" /> and concise to engage participants{' '}
        <br /> effectively. Then upload them right in our <br className="hidden sm:grid" /> platform.
      </div>
    </div>
  </div>
);

export default CreateTwoCard;
