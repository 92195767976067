import * as React from 'react';
import RadioGroup, { Options } from '../inputs/RadioGroup';
import { ModalName } from '../../types/types';
import ContactForm from '../footer/ContactForm';
type Props = {
  handleChangeEmail: (e: any) => void;
  handleChangeMessage: (e: any) => void;
  email: string;
  message: string;
  feedbackSent: boolean;
  validationErrors: Record<string, string>;
  handleEmailClick: (e: any) => void;
  handleClose: (e: React.MouseEvent) => void;
};

const ContactModal = ({
  handleChangeEmail,
  handleChangeMessage,
  handleEmailClick,
  email,
  message,
  feedbackSent,
  validationErrors,
  handleClose
}: Props) => {
  return (
    <div className="grid relative rounded-[12px] mx-auto w-full  sm:w-1/2 xl:w-1/3 h-full bg-white z-50 text-black font-bold font-custom text-xl text-center my-5 sm:my-0 contactUs-modal">
      <button onClick={handleClose} className="text-black absolute top-3 right-5 hover:cursor-pointer">
        x
      </button>
      <h1 className="mt-4">Contact Us</h1>
      {feedbackSent && (
        <div className="contact-us-submitted">
          Thank you for your feedback!
        </div>
      )}
      {!feedbackSent && (
        <ContactForm
          handleChangeEmail={handleChangeEmail}
          handleChangeMessage={handleChangeMessage}
          handleEmailClick={handleEmailClick}
          email={email}
          message={message}
          validationErrors={validationErrors}
        />
      )}
    </div>
  );
};

export default ContactModal;
