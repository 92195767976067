import * as React from 'react';
import PrimaryInput from '../inputs/PrimaryInput';
import PrimaryButton from '../buttons/PrimaryButton';

type Props = {
  value: string;
  handleEmailChange: (e: any) => void;
  handleEmailClick: (e: any) => void;
  className: string;
  validationErrors: Record<string, string>;
};

const EmailFromTop = ({ handleEmailChange, value, handleEmailClick, className, validationErrors }: Props) => (
  <form className={className}>
    <div className="mt-3 h-12 sm:h-8 xxl:h-10 items-center">
      <PrimaryInput
        type="text"
        placeHolder="Your Email Address"
        className="border-neutral placeholder:text-neutral text-purple placeholder:text-center sm:placeholder:text-start pl-2 placeholder:text-sm sm:placeholder:text-xs text-sm sm:text-xs text-center sm:text-start w-full sm:w-32 md:w-48 lg:w-52 xl:w-60 xxl:w-72 h-full rounded-[4px] border-[1px] mr-1 xl:mr-1.5 mb-4 sm:mb-0 focus:outline-purple/75"
        value={value}
        name="email"
        handleChange={handleEmailChange}
      />
      <PrimaryButton
        className="bg-purple w-full sm:w-28 h-full rounded-[8px] text-white text-sm sm:text-sm mb-12"
        name="Subscribe Here"
        onClick={handleEmailClick}
      />
      {validationErrors['email'] ? (
        <div className="-mt-10 sm:-mt-12 ml-2 text-xs text-center sm:text-left text-red-500 ">
          {validationErrors['email']}
        </div>
      ) : (
        <div className="-mt-10 sm:-mt-12 ml-2 text-xs text-center sm:text-left text-red-500 ">
          {validationErrors['emailVerified']}
        </div>
      )}
    </div>
  </form>
);

export default EmailFromTop;
